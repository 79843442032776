
import Form from './Form.vue'
import { useStore } from 'vuex'
import { defineComponent, ref, onMounted, computed, toRefs, reactive } from 'vue'
import swal from 'sweetalert2'

export default defineComponent({
  components: {
    Form
  },
  setup () {
    const form = reactive({
      id: '',
      district_code: '',
      district_name: '',
      district_manager_id: ''
    })
    const form_action = ref('save')
    const visible = ref(false)
    const store = useStore()

    const fields = ref([
      {
        title: 'District Code',
        dataIndex: 'district_code',
      },
      {
        title: 'District Name',
        dataIndex: 'district_name',
      },
      {
        title: 'District Manager',
        dataIndex: 'district_manager.full_name',
      },
      // {
      //   title: "Is Active",
      //   dataIndex: "is_active",
      //   align: 'center',
      //   slots: { customRender: "is_active" }
      // },
      {
        title: 'Action',
        dataIndex: 'action',
        slots: {customRender: 'action'},
        align: 'center'
      },
    ])

    const index = (payload = {page: 1}) => {
      store.dispatch('District/index', payload)
    }

    const editRecord = (record: any) => {
      form_action.value = 'update'
      const {id, district_code, district_name, district_manager_id} = toRefs(form)
      id.value = record.id
      district_code.value = record.district_code
      district_name.value = record.district_name
      district_manager_id.value = record.district_manager_id
      visible.value = true
    }

    const createRecord = () => {
      form_action.value = 'save'
      const {id, district_code, district_name, district_manager_id} = toRefs(form)
      id.value = ''
      district_code.value = ''
      district_name.value = ''
      district_manager_id.value = ''
      visible.value = true
    }

    const deleteRecord = (record: any) => {
      store.dispatch('District/destroy', record.id)
    }

    const handleSaving = (record: any) => {
      form_action.value == 'save' ? storeRecord(record) : updateRecord(record)
    }

    const storeRecord = (payload = {}) => {
      store
          .dispatch('District/store', payload)
          .then(() => (visible.value = false))
    }

    const updateRecord = (payload = {}) => {
      store
          .dispatch('District/update', payload)
          .then(() => (visible.value = false))
    }

    const filterRecord = (payload: any) => {
      index(payload)
    }

    const items = computed(() => store.getters['District/items'])
    const loading = computed(() => store.getters['District/status'])

    onMounted(index)

    return {
      loading,
      items,
      fields,
      visible,
      form,
      editRecord,
      deleteRecord,
      storeRecord,
      createRecord,
      updateRecord,
      handleSaving,
      filterRecord
    }
  },
})
